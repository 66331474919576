import {RUBLE_SIGN_SYMBOL} from "./constants";

function formatPrice(val: string): string {
    return `${val} ${RUBLE_SIGN_SYMBOL}`;
}

export function parsePrice(val: any): number {
    return +val || 0;
}

export function getAbsolutePriceString(modifierPrice: number): string {
    return `${modifierPrice}`;
}

export function getRelativePriceString(modifierPrice: number, baseProductPrice?: number): string {
    const price = baseProductPrice !== undefined
        ? modifierPrice - baseProductPrice
        : modifierPrice;

    if(Math.abs(price) < 0.01) {
        return '';
    }

    const sign = price > 0 ? '+' : '-';
    return `${sign}${price}`;
}
